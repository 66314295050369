import { FORM_ERRORS_TEXT } from "@/utils/constants";
import { parse, format } from "date-fns";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { usePdfSubmissionStore } from "@/stores/pdfSubmissionStore";
import { storeToRefs } from "pinia";

export function hasDuplicate(items, newItem) {
  return items.some((item) => item.label === newItem.label);
}

export function convertDateFormat(date) {
  if (!date) {
    return "";
  }
  const dateWithoutTime = date.substring(0, 10);

  return format(parse(dateWithoutTime, "yyyy-MM-dd", new Date()), "MMM dd, yyyy");
}

export function titleCaseMixedString(str) {
  if (!str) {
    return "";
  }
  return str
    .replace(/_/g, " ")
    .replace(/([A-Z])/g, " $1")
    .replace(/([0-9])/g, " $1")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function titleCaseString(str) {
  if (!str) {
    return "";
  }
  return str
    .replace(/_/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function convertDateToYYYYMMDD(date) {
  if (!date) {
    return "";
  }
  // array of possible date formats
  const dateFormats = ["yyyy-MM-dd", "MMM dd, yyyy", "MM/dd/yyyy", "MM-dd-yyyy", "MM.dd.yyyy", "MM dd yyyy"];
  // loop through array of possible date formats and return the first one that parses correctly
  for (let i = 0; i < dateFormats.length; i++) {
    const parsedDate = parse(date, dateFormats[i], new Date());
    if (parsedDate.toString() !== "Invalid Date") {
      return format(parsedDate, "yyyy-MM-dd");
    }
  }
}

export function generateRandom4DigitNumber() {
  return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
}

export function sendFormByEnterClicking(event, sendRequest) {
  if (event.code === "Enter" || event.code === 76) {
    sendRequest();
  }
}

export function tryParseApiErrors(error) {
  let resultErrors = [];

  const validationErrors = error.response.data.validation_errors;

  if (validationErrors) {
    for (let key in validationErrors) {
      resultErrors.push(...validationErrors[key]);
    }
  } else if (error.response.data.source_error) {
    resultErrors = [error.response.data.source_error];
  } else {
    resultErrors = [FORM_ERRORS_TEXT.UNKNOWN_SERVER_ERROR];
  }

  return resultErrors;
}

export function initializePatientInfo() {
  const { orderInfo } = storeToRefs(useOrderInfoStore());
  orderInfo.value.patientInfo = {};
  orderInfo.value.patientInfo.id = null;
  orderInfo.value.patientInfo.prefix = null;
  orderInfo.value.patientInfo.firstName = null;
  orderInfo.value.patientInfo.middleName = null;
  orderInfo.value.patientInfo.lastName = null;
  orderInfo.value.patientInfo.suffix = null;
  orderInfo.value.patientInfo.dob = null;
  orderInfo.value.patientInfo.insuranceId = null;
  orderInfo.value.patientInfo.phone = null;
  orderInfo.value.patientInfo.gender = null;
  orderInfo.value.patientInfo.address1 = null;
  orderInfo.value.patientInfo.address2 = null;
  orderInfo.value.patientInfo.city = null;
  orderInfo.value.patientInfo.state = null;
  orderInfo.value.patientInfo.zip = null;
  orderInfo.value.patientInfo.weight = null;
  orderInfo.value.patientInfo.height = null;
  orderInfo.value.patientInfo.drugAllergies = null;
  orderInfo.value.patientInfo.ssn = null;
  orderInfo.value.patientInfo.insuranceMemberName = null;
}

export function initializeProviderInfo() {
  const { orderInfo } = storeToRefs(useOrderInfoStore());
  orderInfo.value.providerInfo = {};
  orderInfo.value.providerInfo.id = null;
  orderInfo.value.providerInfo.providerFirstName = null;
  orderInfo.value.providerInfo.providerLastName = null;
  orderInfo.value.providerInfo.providerTitle = null;
  orderInfo.value.providerInfo.contactName = null;
  orderInfo.value.providerInfo.contactPhone = null;
  orderInfo.value.providerInfo.contactFax = null;
  orderInfo.value.providerInfo.npiNumber = null;
  orderInfo.value.providerInfo.tin = null;
  orderInfo.value.providerInfo.specialty = null;
  orderInfo.value.providerInfo.officeName = null;
  orderInfo.value.providerInfo.officePhone = null;
  orderInfo.value.providerInfo.fax = null;
  orderInfo.value.providerInfo.email = null;
  orderInfo.value.providerInfo.providerAddress1 = null;
  orderInfo.value.providerInfo.providerAddress2 = null;
  orderInfo.value.providerInfo.providerCity = null;
  orderInfo.value.providerInfo.providerState = null;
  orderInfo.value.providerInfo.providerZip = null;
  orderInfo.value.providerInfo.deaNumber = null;
  orderInfo.value.providerInfo.licenseNumber = null;
}

export function initializeDrugsTried() {
  const { orderInfo } = storeToRefs(useOrderInfoStore());
  let stepTherapyItems = 0;
  if (orderInfo.value.stepTherapyRequired.length) {
    orderInfo.value.stepTherapyRequired.forEach((item) => {
      if (item.stepTherapyItems.length) {
        stepTherapyItems += item.stepTherapyItems.length;
      }
    });
  } else {
    stepTherapyItems = 3;
  }
  for (let i = 0; i < stepTherapyItems; i++) {
    orderInfo.value.drugsTried[i] = {};
    orderInfo.value.drugsTried[i].drugTried = null;
    orderInfo.value.drugsTried[i].startDate = null;
    orderInfo.value.drugsTried[i].endDate = null;
    orderInfo.value.drugsTried[i].resultOfTrial = null;
  }
}

export function initializePrimaryDiagnosis() {
  const { orderInfo } = storeToRefs(useOrderInfoStore());
  orderInfo.value.primaryDiagnosis = {};
  orderInfo.value.primaryDiagnosis.diagnosis = null;
  orderInfo.value.primaryDiagnosis.icd10Code = null;
}

export function initializeOtherDiagnosis() {
  const { orderInfo } = storeToRefs(useOrderInfoStore());
  for (let i = 0; i < 6; i++) {
    const diagnosisObject = { diagnosis: null, icd10Code: null };
    orderInfo.value.otherDiagnosis[i] = diagnosisObject;
  }
}

export function clearPdfSubmissionStorage() {
  const { pdfSubmission } = storeToRefs(usePdfSubmissionStore());
  pdfSubmission.value.patientInfo = {};
  pdfSubmission.value.patientInfo.id = null;
  pdfSubmission.value.patientInfo.prefix = null;
  pdfSubmission.value.patientInfo.firstName = null;
  pdfSubmission.value.patientInfo.middleName = null;
  pdfSubmission.value.patientInfo.lastName = null;
  pdfSubmission.value.patientInfo.suffix = null;
  pdfSubmission.value.patientInfo.dob = null;
  pdfSubmission.value.patientInfo.insuranceId = null;
  pdfSubmission.value.orderKey = null;
  pdfSubmission.value.medication = null;
  pdfSubmission.value.insuranceProvider = null;
  pdfSubmission.value.insurancePlanType = null;
  pdfSubmission.value.benefitType = null;
  pdfSubmission.value.priorAuthFormId = null;
  pdfSubmission.value.formSubmitted = null;
  pdfSubmission.value.supportingDocs = [];
  pdfSubmission.value.priorAuthNumber = null;
  pdfSubmission.value.priorAuthExpiration = null;
  pdfSubmission.value.submitForProcessing = null;
  pdfSubmission.value.signature = null;
}

export function clearOrderInfoStorage() {
  const { orderInfo } = storeToRefs(useOrderInfoStore());
  initializePatientInfo();
  initializeProviderInfo();
  initializePrimaryDiagnosis();
  initializeOtherDiagnosis();
  if (orderInfo.value.drugsTried.length) {
    for (let i = 0; i < orderInfo.value.drugsTried.length; i++) {
      orderInfo.value.drugsTried[i].drugTried = null;
      orderInfo.value.drugsTried[i].startDate = null;
      orderInfo.value.drugsTried[i].endDate = null;
      orderInfo.value.drugsTried[i].resultOfTrial = null;
    }
  }
  orderInfo.value.medication = null;
  orderInfo.value.strength = null;
  orderInfo.value.routeAdministration = null;
  orderInfo.value.startDate = null;
  orderInfo.value.frequency = null;
  orderInfo.value.expectedLengthOfTherapy = null;
  orderInfo.value.quantityPer30Days = null;
  orderInfo.value.drugQuantityQualifier = null;
  orderInfo.value.expeditedReviewCheck = null;
  orderInfo.value.expeditedReviewStatement = null;
  orderInfo.value.expeditedReviewWithin24h = null;
  orderInfo.value.checklist = [];
  orderInfo.value.checklistLlmScores = [];
  orderInfo.value.insuranceProvider = null;
  orderInfo.value.insurancePlanType = null;
  orderInfo.value.benefitType = null;
  orderInfo.value.medicalNecessity = null;
  orderInfo.value.labResults = [];
  orderInfo.value.patientChart = [];
  orderInfo.value.guidelines = [];
  orderInfo.value.placeOfAdministration = null;
  orderInfo.value.placeOfAdministrationDetails = {};
  orderInfo.value.placeOfAdministrationDetails.name = null;
  orderInfo.value.placeOfAdministrationDetails.phone = null;
  orderInfo.value.administrationCodesCpt = null;
  orderInfo.value.placeOfAdministrationAddress = null;
  orderInfo.value.orderKey = null;
  orderInfo.value.formStep = null;
  orderInfo.value.stepTherapyRequired = [];
  orderInfo.value.payerQuestions = [];
  orderInfo.value.medicalNecessityTemplate = null;
  orderInfo.value.user = null;
  orderInfo.value.createdOn = null;
  orderInfo.value.modifiedOn = null;
  orderInfo.value.signature = null;
  orderInfo.value.stageOfTherapy = null;
  orderInfo.value.pharmacyInfo = null;
  orderInfo.value.bin = null;
  orderInfo.value.processorControlNumber = null;
  orderInfo.value.grpNumber = null;
  orderInfo.value.faxDescription = null;
  orderInfo.value.submissionMethod = null;
}
